<template>
  <div class="shift-container">
      <a-modal
      v-model="visibleModalManageShift"
      class="modal-shift"
      :title="status.isEdit ? 'Form Ubah Shift' : 'Form Tambah Shift'"
      @cancel="toggleModalManageShift"
      centered
      >
        <form-shift
          :newShift="newShift"
          :shiftDefault="shiftDefault"
          :hasActive="hasActive"
          :dayColumns="dayColumns"
          :dayData="dayData"
          @change-status-day="onChangeStatusDay"
          @handle-change="handleChange"
        />
        <template slot="footer">
          <a-button size="large" key="back" @click="toggleModalManageShift">
            Batal
          </a-button>
          <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManageShift" @click="handleOkModalManageShift">
            {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
          </a-button>
        </template>
      </a-modal>
      <a-modal
      v-model="visibleModalManageEmployee"
      class="modal-shift"
      :title="`Atur Shift ${manageEmployeeData.nama}`"
      @cancel="toggleModalManageEmployee"
      :destroyOnClose="true"
      centered
      >
        <manage-employee
          :manageEmployeeData="manageEmployeeData"
        />
        <template slot="footer">
          <a-button class="d-none m-0" size="large" key="back" @click="toggleModalManageEmployee">
            Tutup
          </a-button>
        </template>
      </a-modal>
      <div class="d-flex align-items center">
        <div class="ml-auto">
          <a-button @click.prevent="toggleModalManageShift('add')" size="large" type="primary"><a-icon type="plus" />Tambah Shift</a-button>
        </div>
      </div>
      <div class="mt-4">
        <a-table
          :columns="shiftColumns"
          :data-source="shiftDataTable"
          :pagination="pagination"
          :loading="loadingTable"
          bordered
        >
          <div slot="aksi" slot-scope="value, record">
            <a-button
              @click.prevent="toggleModalManageEmployee('open', record)"
              class="mr-3 text-info border border-info"
              size="large"
            >
              <a-icon type="team" />Atur Pegawai
            </a-button>
            <a-button
              @click.prevent="toggleModalManageShift('edit', record)"
              class="mr-3 text-warning border border-warning"
              size="large"
            >
              <a-icon type="edit"/>Ubah
            </a-button>
            <a-button
              @click.prevent="handleDeleteShift(record)"
              class="text-danger border border-danger"
              size="large"
            >
              <a-icon type="delete" />Hapus
            </a-button>
          </div>
        </a-table>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
const formShift = () => import('@/components/app/AdminHR/Shift/FormShift')
const manageEmployee = () => import('@/components/app/AdminHR/Shift/ManageEmployee')

const shiftColumns = [
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Total Pegawai',
    dataIndex: 'totalPegawai',
    key: 'totalPegawai',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'totalPegawai' },
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
const dayColumns = [
  {
    title: 'Status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Hari',
    dataIndex: 'hari',
  },
  {
    title: 'Jam Masuk',
    dataIndex: 'jam_masuk',
    scopedSlots: { customRender: 'jam_masuk' },
  },
  {
    title: 'Jam Keluar',
    dataIndex: 'jam_keluar',
    scopedSlots: { customRender: 'jam_keluar' },
  },
  {
    title: 'Toleransi(menit)',
    dataIndex: 'toleransi',
    scopedSlots: { customRender: 'toleransi' },
  },
  {
    title: 'Jam Istirahat',
    dataIndex: 'jam_istirahat',
    scopedSlots: { customRender: 'jam_istirahat' },
  },
  {
    title: 'Durasi Istirahat (menit)',
    dataIndex: 'periode_jam_istirahat',
    scopedSlots: { customRender: 'periode_jam_istirahat' },
  },
  {
    title: 'Jumlah Jam Kerja',
    dataIndex: 'jumlah_jam_kerja',
    scopedSlots: { customRender: 'jumlah_jam_kerja' },
  },
]
const dayData = [
  {
    key: 1,
    id: null,
    id_shift: null,
    hari: 'Senin',
    jam_masuk: moment('08:00', 'HH:mm'),
    jam_keluar: moment('17:00', 'HH:mm'),
    toleransi: 0,
    jam_istirahat: moment('12:00', 'HH:mm'),
    periode_jam_istirahat: 60,
    jumlah_jam_kerja: '8 H 0 m',
    status: 'active',
  },
  {
    key: 2,
    id: null,
    id_shift: null,
    hari: 'Selasa',
    jam_masuk: moment('08:00', 'HH:mm'),
    jam_keluar: moment('17:00', 'HH:mm'),
    toleransi: 0,
    jam_istirahat: moment('12:00', 'HH:mm'),
    periode_jam_istirahat: 60,
    jumlah_jam_kerja: '8 H 0 m',
    status: 'active',
  },
  {
    key: 3,
    id: null,
    id_shift: null,
    hari: 'Rabu',
    jam_masuk: moment('08:00', 'HH:mm'),
    jam_keluar: moment('17:00', 'HH:mm'),
    toleransi: 0,
    jam_istirahat: moment('12:00', 'HH:mm'),
    periode_jam_istirahat: 60,
    jumlah_jam_kerja: '8 H 0 m',
    status: 'active',
  },
  {
    key: 4,
    id: null,
    id_shift: null,
    hari: 'Kamis',
    jam_masuk: moment('08:00', 'HH:mm'),
    jam_keluar: moment('17:00', 'HH:mm'),
    toleransi: 0,
    jam_istirahat: moment('12:00', 'HH:mm'),
    periode_jam_istirahat: 60,
    jumlah_jam_kerja: '8 H 0 m',
    status: 'active',
  },
  {
    key: 5,
    id: null,
    id_shift: null,
    hari: 'Jumat',
    jam_masuk: moment('08:00', 'HH:mm'),
    jam_keluar: moment('17:00', 'HH:mm'),
    toleransi: 0,
    jam_istirahat: moment('12:00', 'HH:mm'),
    periode_jam_istirahat: 60,
    jumlah_jam_kerja: '8 H 0 m',
    status: 'active',
  },
  {
    key: 6,
    id: null,
    id_shift: null,
    hari: 'Sabtu',
    jam_masuk: moment('08:00', 'HH:mm'),
    jam_keluar: moment('17:00', 'HH:mm'),
    toleransi: 0,
    jam_istirahat: moment('12:00', 'HH:mm'),
    periode_jam_istirahat: 60,
    jumlah_jam_kerja: '8 H 0 m',
    status: 'active',
  },
  {
    key: 7,
    id: null,
    id_shift: null,
    hari: 'Minggu',
    jam_masuk: moment('08:00', 'HH:mm'),
    jam_keluar: moment('17:00', 'HH:mm'),
    toleransi: 0,
    jam_istirahat: moment('12:00', 'HH:mm'),
    periode_jam_istirahat: 60,
    jumlah_jam_kerja: '8 H 0 m',
    status: 'active',
  },
]
const shiftDefault = {
  jam_masuk: moment('08:00', 'HH:mm'),
  jam_keluar: moment('17:00', 'HH:mm'),
  toleransi: 0,
  jam_istirahat: moment('12:00', 'HH:mm'),
  periode_jam_istirahat: 60,
  jumlah_jam_kerja: '8 H 0 m',
  totalWork: '56 H 0 m',
}

export default {
  components: {
    formShift,
    manageEmployee,
  },
  data() {
    return {
      shiftColumns,
      dayColumns,
      dayData,
      shiftDefault,
      loadingTable: false,
      loadingDeleteShift: false,
      visibleModalManageShift: false,
      visibleModalManageEmployee: false,
      loadingActionModalManageShift: false,
      shiftDataTable: [],
      pagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newShift: {
        nama: null,
        absensi_dibuka: 0,
        absensi_ditutup: 0,
      },
      idEdit: null,
      manageEmployeeData: {
        nama: null,
      },
    }
  },
  methods: {
    moment,
    resetShift() {
      this.shiftDefault = {
        jam_masuk: moment('08:00', 'HH:mm'),
        jam_keluar: moment('17:00', 'HH:mm'),
        toleransi: 0,
        jam_istirahat: moment('12:00', 'HH:mm'),
        periode_jam_istirahat: 60,
        jumlah_jam_kerja: '8 H 0 m',
        totalWork: '56 H 0 m',
      }
      const days = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']
      this.dayData = this.dayData.map((el, index) => {
        return {
          ...el,
          id: null,
          id_shift: null,
          hari: days[index],
          jam_masuk: moment('08:00', 'HH:mm'),
          jam_keluar: moment('17:00', 'HH:mm'),
          toleransi: 0,
          jam_istirahat: moment('12:00', 'HH:mm'),
          periode_jam_istirahat: 60,
          jumlah_jam_kerja: '8 H 0 m',
          status: 'active',
        }
      })
    },
    onChangeStatusDay(data) {
      const target = this.dayData.filter(el => el.key === data.key)[0]
      if (target) {
        if (data.status === 'active') {
          target.status = 'inactive'
        } else {
          target.status = 'active'
        }
      }
    },
    handleChange(payload) {
      const { value, type, column, data } = payload
      if (type === 'all') {
        this.shiftDefault[column] = value
        const jumlahJamKerja = this.parsingWorkHour(this.shiftDefault.jam_masuk, this.shiftDefault.jam_keluar, this.shiftDefault.periode_jam_istirahat)
        this.shiftDefault.jumlah_jam_kerja = jumlahJamKerja

        for (let idx = 0; idx < this.dayData.length; idx++) {
          this.dayData[idx][column] = value
          this.dayData[idx].jam_masuk = this.shiftDefault.jam_masuk
          this.dayData[idx].jam_keluar = this.shiftDefault.jam_keluar
          this.dayData[idx].jam_istirahat = this.shiftDefault.jam_istirahat
          this.dayData[idx].jumlah_jam_kerja = jumlahJamKerja
        }
      } else {
        const target = this.dayData.filter(el => el.key === data.key)[0]
        if (target) {
          target[column] = value
          target.jumlah_jam_kerja = this.parsingWorkHour(target.jam_masuk, target.jam_keluar, target.periode_jam_istirahat)
        }
      }
    },
    parsingWorkHour(startTime, endTime, breakDuration) {
      breakDuration = breakDuration * 60
      let totalSec = endTime.diff(startTime, 'seconds')
      totalSec = totalSec - breakDuration

      const hours = parseInt(totalSec / 3600) % 24
      const minutes = (parseInt(totalSec / 60)) % 60

      const result = (hours) + 'H ' + (minutes) + 'm'
      return result
    },
    toggleModalManageShift(status, data) {
      this.resetShift()
      this.visibleModalManageShift = !this.visibleModalManageShift
      if (!this.visibleModalManageShift) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newShift = {
            nama: null,
            absensi_dibuka: 0,
            absensi_ditutup: 0,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newShift = {
            nama: data.nama,
            absensi_dibuka: data.absensi_dibuka,
            absensi_ditutup: data.absensi_ditutup,
          }
          console.log(data)
          let key = 1
          this.dayData = data.dataJadwal.map(el => {
            const jamMasuk = moment(el.jam_masuk, 'HH:mm')
            const jamKeluar = moment(el.jam_keluar, 'HH:mm')
            const jamIstirahat = moment(el.jam_istirahat, 'HH:mm')
            const breakDuration = el.periode_jam_istirahat
            const jumlahJamKerja = this.parsingWorkHour(jamMasuk, jamKeluar, breakDuration)
            return {
              ...el,
              key: key++,
              jam_masuk: jamMasuk,
              jam_keluar: jamKeluar,
              jam_istirahat: jamIstirahat,
              jumlah_jam_kerja: jumlahJamKerja,
            }
          })
          // console.log('after', this.dayData)
        }
      }
    },
    handleOkModalManageShift() {
      const content = `${this.status.isEdit ? 'Apakah anda yakin ingin mengubah shift ini? perubahan ini akan mempengaruhi sistem absensi pegawai' : 'Apakah anda yakin ingin membuat shift baru ini? perubahan ini akan disimpan kedalam sistem'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Shift tidak dapat ditambahkan jika tidak ada nama shift dan semua daftar shift tidak aktif',
        })
      } else {
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageShift = true
            const dataJadwal = this.dayData.map(el => {
              return {
                ...el,
                jam_masuk: moment(el.jam_masuk, 'HH:mm').format('HH:mm'),
                jam_keluar: moment(el.jam_keluar, 'HH:mm').format('HH:mm'),
                jam_istirahat: moment(el.jam_istirahat, 'HH:mm').format('HH:mm'),
                jumlah_jam_kerja: 0,
              }
            })
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataShift: this.newShift,
              dataJadwal: dataJadwal,
            } : {
              dataShift: this.newShift,
              dataJadwal: dataJadwal,
            }
            console.log('payload', payload)
            this.$store.dispatch(`shift/${this.status.isEdit ? 'EDIT' : 'POST'}_SHIFT`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageShift = false
                this.toggleModalManageShift()
                this.fetchDataShift()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Shift berhasil diubah' : 'Shift baru berhasil ditambahkan'
                  this.$notification.success({
                    message: 'Berhasil',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Shift gagal diubah' : 'Shift baru gagal ditambahkan'
                  this.$notification.error({
                    message: 'Gagal',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleDeleteShift(data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin menghapus shift <b>{data.nama}</b> ? perubahan ini akan mempengaruhi sistem absensi pegawai</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.loadingDeleteShift = true
          this.$store.dispatch('shift/DELETE_SHIFT', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataShift()
              this.loadingDeleteShift = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    'Shift berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Shift gagal dihapus',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteShift = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
    fetchDataShift(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('shift/FETCH_SHIFT', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.loadingTable = false
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          this.shiftDataTable = res.shift.map(el => {
            return {
              key: el.id,
              id: el.id,
              nama: el.nama,
              absensi_dibuka: el.absensi_dibuka,
              absensi_ditutup: el.absensi_ditutup,
              totalPegawai: el.shift_pegawais.length,
              pegawai: el.shift_pegawais,
              dataJadwal: el.jadwal_shifts,
            }
          })
        })
    },
    toggleModalManageEmployee(status, data) {
      this.visibleModalManageEmployee = !this.visibleModalManageEmployee
      if (!this.visibleModalManageEmployee) {
        setTimeout(() => {
          this.fetchDataShift()
          this.manageEmployeeData = {
            nama: null,
          }
        }, 500)
        return ''
      }

      if (status) {
        this.manageEmployeeData = data
      }
    },
  },
  mounted() {
    this.fetchDataShift()
  },
  computed: {
    hasActive() {
      return this.dayData.filter(el => el.status === 'active').length
    },
    isValidateForm() {
      if (this.newShift.nama && this.hasActive !== 0) {
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
.modal-shift{
  .ant-modal-wrap {
    .ant-modal {
      width: 95% !important;
      // height: 95% !important;
    }
  }
}
.modal-add-employee{
  .ant-modal-wrap {
    .ant-modal {
      width: 70% !important;
      // height: 95% !important;
    }
  }
}
// }

</style>
